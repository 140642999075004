/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, graphql } from "gatsby"
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri"
import ReactMarkdown from "react-markdown"
import CodeBlock from "../components/code-block"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../assets/scss/featured-banner.scss"

const blogPostStyles = {
  articleContainer: {
    variant: "variants.container",
  },
  featuredBanner: {
    pt: [2],
  },
  postContent: {
    p: 0,
    borderTopColor: "inherit",
  },
  converkit: {
    mx: [0, 0, 3, 0],
    my: [4, 4, 5],
  },
}

const PostPagination = props => (
  <nav className="pagination -post">
    <ul>
      <li>
        {props.previous && (
          <Link
            key={props.previous.id}
            to={props.previous.prefix + props.previous.Slug + "/"}
            rel="prev"
          >
            <p>
              <span className="icon">
                <RiArrowLeftSLine />
              </span>{" "}
              Previous
            </p>
            {props.previous.Title}
          </Link>
        )}
      </li>
      <li>
        {props.next && (
          <Link
            key={props.next.id}
            to={props.next.prefix + props.next.Slug + "/"}
            rel="next"
          >
            <p>
              Next
              <span className="icon">
                <RiArrowRightSLine />
              </span>
            </p>
            {props.next.Title}
          </Link>
        )}
      </li>
    </ul>
  </nav>
)

const Newsletter = ({ data, pageContext }) => {
  const { strapiNewsletters } = data

  const {
    id,
    SocialMedia,
    Title,
    meta_description,
    Content,
    PublishDate,
  } = strapiNewsletters

  const { previous, next } = pageContext

  const props = {
    previous,
    next,
  }

  const socialImage =
    SocialMedia && (SocialMedia.childImageSharp.original.src || "")

  return (
    <Layout key={id}>
      <SEO
        title={Title + " • Stackrole"}
        description={meta_description ? meta_description : ""}
        image={socialImage}
        article={true}
      />
      <article className="blog-post" sx={blogPostStyles.articleContainer}>
        <header className="featured-banner" sx={blogPostStyles.featuredBanner}>
          <section className="article-header">
            <h1>{Title}</h1>
            <time>{PublishDate}</time>
          </section>
        </header>

        <div className="blog-post-content">
          <ReactMarkdown source={Content} renderers={{ code: CodeBlock }} />
        </div>
        <div className="blog-post-content" sx={blogPostStyles.postContent}>
          <PostPagination {...props} />
        </div>
      </article>
    </Layout>
  )
}

export default Newsletter

export const pageQuery = graphql`
  query newsletterPageQuery($id: String!) {
    strapiNewsletters(id: { eq: $id }) {
      id
      Title
      SocialMedia {
        childImageSharp {
          original {
            src
          }
        }
      }
      PublishDate(formatString: "MMMM DD, YYYY")
      meta_description
      Slug
      prefix
      Content
    }
  }
`
